import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  ClickAwayListener,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from '@mui/material';
import Transitions from 'components/@extended/Transitions';
import usePermissions from 'hooks/usePermissions';
import useFeatures from 'hooks/useFeatures';
import { BorderOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { UserLayer } from 'pages/admin/constant';
import featuresCods from 'constants/features';

import NavItem from './NavItem';

const PopperStyled = styled(Popper)(({ theme }) => ({
  overflow: 'visible',
  zIndex: 1202,
  minWidth: 180,
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 38,
    left: -5,
    width: 10,
    height: 10,
    backgroundColor: theme.palette.background.paper,
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 120,
    borderLeft: `1px solid ${theme.palette.grey.A800}`,
    borderBottom: `1px solid ${theme.palette.grey.A800}`,
  },
}));

const NavCollapse = ({ menu, level }) => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const { role, userRoleType } = usePermissions();
  const {
    hasTrustedDestinationFeature,
    hasELinksFeature,
    hasIpExclusionsFeature,
  } = useFeatures();
  const menuState = useSelector(state => state.menu);
  const { drawerOpen, openItem } = menuState;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [children, setChildren] = useState([]);

  const handleClick = (event) => {
    setAnchorEl(null);
    if (drawerOpen) {
      setOpen(!open);
      setSelected(!selected ? menu.id : null);
    } else {
      setAnchorEl(event?.currentTarget);
    }
  };

  const handleClose = () => setAnchorEl(null);

  const openMini = Boolean(anchorEl);

  const navCollapse = children.map((item) => {
    switch (item.type) {
      case 'collapse':
        return <NavCollapse key={item.id} menu={item} level={level + 1} />;
      case 'item':
        if ((item.id === featuresCods.E_LINKS && !hasELinksFeature) ||
            (item.id === featuresCods.TRUSTED_DESTINATIONS && !hasTrustedDestinationFeature) ||
            (item.id === featuresCods.PENDING_LINKS && !hasTrustedDestinationFeature) ||
            (item.id === featuresCods.IP_EXCLUSIONS && !hasIpExclusionsFeature)) {
          return null;
        }

        return <NavItem key={item.id} item={item} level={level + 1} />;
      default:
        return (
          <Typography key={item.id} variant="body1" color="error" align="center">
            Fix - Collapse or Item
          </Typography>
        );
    }
  });

  const borderIcon = level === 1 ? <BorderOutlined style={{ fontSize: '1rem' }} /> : false;
  const Icon = menu.icon;
  const menuIcon = menu.icon ? <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} /> : borderIcon;
  const textColor = theme.palette.mode === 'dark' ? 'grey.400' : 'text.secondary';
  const iconSelectedColor =
    theme.palette.mode === 'dark' && drawerOpen ? theme.palette.text.secondary : theme.palette.secondary.main;

  useEffect(() => {
    setChildren(
      menu.children.filter((item) => {
        // check if the user has permission to view this route by sidebar
        if (userRoleType === UserLayer.Domain) {
          if (item.hasOwnProperty('unauthorizedRoles')) {
            return !item.unauthorizedRoles.includes(role.code);
          }
        }
        return true;
      }),
    );
  }, [userRoleType, menu, role]);

  useEffect(() => {
    const isCollapseSelected = children.some(item => openItem.includes(item.id));

    if (!isCollapseSelected) {
      setSelected(null);
    } else {
      setSelected(menu.id);
    }
  }, [pathname, openItem, menu, children]);

  return (
    <Tooltip
      title={
        drawerOpen ? '' : menu.title
      }
    >
      <ListItemButton
        disableRipple
        selected={selected === menu.id}
        {...(!drawerOpen && { onMouseEnter: handleClick, onMouseLeave: handleClose })}
        onClick={handleClick}
        sx={{
          pl: drawerOpen ? `${level * 28}px` : 1.5,
          py: !drawerOpen && level === 1 ? 1.25 : 1,
          ...(drawerOpen && {
            '&:hover': {
              bgcolor: theme.palette.mode === 'dark' ? 'divider' : 'secondary.lighter',
            },
            '&.Mui-selected': {
              bgcolor: 'transparent',
              color: iconSelectedColor,
              '&:hover': {
                color: iconSelectedColor,
                bgcolor: theme.palette.mode === 'dark' ? 'divider' : 'transparent',
              },
            },
          }),
          ...(!drawerOpen && {
            '&:hover': {
              bgcolor: 'transparent',
            },
            '&.Mui-selected': {
              '&:hover': {
                bgcolor: 'transparent',
              },
              bgcolor: 'transparent',
            },
          }),
        }}
      >
        {menuIcon && (
          <ListItemIcon
            sx={{
              minWidth: 28,
              color: selected === menu.id ? 'secondary.main' : textColor,
              ...(!drawerOpen && {
                borderRadius: 1.5,
                width: 36,
                height: 36,
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover': {
                  bgcolor: theme.palette.mode === 'dark' ? 'secondary.light' : 'secondary.lighter',
                },
              }),
              ...(!drawerOpen &&
                selected === menu.id && {
                bgcolor: theme.palette.mode === 'dark' ? 'secondary.900' : 'secondary.lighter',
                '&:hover': {
                  bgcolor: theme.palette.mode === 'dark' ? 'secondary.darker' : 'secondary.lighter',
                },
              }),
            }}
          >
            {menuIcon}
          </ListItemIcon>
        )}
        {(drawerOpen || (!drawerOpen && level !== 1)) && (
          <ListItemText
            primary={
              <Typography variant="body1" color={selected === menu.id ? 'secondary' : textColor}>
                {menu.title}
              </Typography>
            }
            secondary={
              menu.caption && (
                <Typography variant="caption" color="secondary">
                  {menu.caption}
                </Typography>
              )
            }
          />
        )}
        {(drawerOpen || (!drawerOpen && level !== 1)) &&
          (openMini || open ? (
            <UpOutlined style={{ fontSize: '0.625rem', marginLeft: 1, color: theme.palette.secondary.main }} />
          ) : (
            <DownOutlined style={{ fontSize: '0.625rem', marginLeft: 1 }} />
          ))}

        {!drawerOpen && (
          <PopperStyled
            open={openMini}
            anchorEl={anchorEl}
            placement="right-start"
            style={{
              zIndex: 2001,
            }}
            popperOptions={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [-12, 1],
                  },
                },
              ],
            }}
          >
            {({ TransitionProps }) => (
              <Transitions in={openMini} {...TransitionProps}>
                <Paper
                  sx={{
                    overflow: 'hidden',
                    mt: 1.5,
                    boxShadow: theme.customShadows.z1,
                    backgroundImage: 'none',
                    border: `1px solid ${theme.palette.divider}`,
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <Box>{navCollapse}</Box>
                  </ClickAwayListener>
                </Paper>
              </Transitions>
            )}
          </PopperStyled>
        )}
      </ListItemButton>
      {drawerOpen && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List sx={{ p: 0 }}>{navCollapse}</List>
        </Collapse>
      )}
    </Tooltip>
  );
};

export default NavCollapse;
