export const BE_FE_LINKS_META = {
  current_page: 'currentPage',
  form: 'form',
  last_page: 'lastPage',
  per_page: 'perPage',
  to: 'to',
  total: 'total',
  first: 'first',
  last: 'last',
  next: 'next',
  prev: 'prev',
};
