// TODO: remove
Object.defineProperty(Array.prototype, 'move', {
  value(from, to) {
    const newArr = structuredClone(this);
    const [element] = newArr.splice(from, 1);
    newArr.splice(to, 0, element);
    return newArr;
  },
  enumerable: false,
});

Object.defineProperty(Array.prototype, 'removeItemWithIndex', {
  value(removedIndex) {
    return this.filter((_, elementIndex) => elementIndex !== removedIndex);
  },
  enumerable: false,
});
