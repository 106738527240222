import { createApi } from '@reduxjs/toolkit/query/react';
import getCustomBaseQuery from '_api/customBaseQuery';

export const linkImportsApi = createApi({
  reducerPath: 'linkImportsApi',
  baseQuery: getCustomBaseQuery(),
  tagTypes: ['LinkImport'],
  endpoints: builder => ({
    // Create initial import record
    createImport: builder.mutation({
      query: ({ domainUuid, formData }) => ({
        url: `/${domainUuid}/links/import/create`,
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    }),

    // Dispatch the import processing with parsed file
    dispatchImport: builder.mutation({
      query: ({ domainUuid, linkImportUuid, formData }) => ({
        url: `/${domainUuid}/links/import/${linkImportUuid}`,
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    }),
  }),
});

export const {
  useCreateImportMutation,
  useDispatchImportMutation,
} = linkImportsApi;
