import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axios';
import { noop } from 'lodash';

export const getMyUserData = createAsyncThunk('auth/getMyUserData', async (_, { rejectWithValue }) => {
  try {
    const { data: responseData } = await axiosInstance.get('/me');

    return responseData;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getUserDomainGroups = createAsyncThunk(
  'auth/getUserDomainGroups',
  async (domainId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/domains/${domainId}/groups`);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getDefaultGroups = createAsyncThunk(
  'auth/getDefaultGroups',
  async (domainId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/auth/profile/domains/${domainId}/default_groups`);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateDefaultGroups = createAsyncThunk(
  'auth/updateDefaultGroups',
  async ({ domainId, groupId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/auth/profile/domains/${domainId}/default_groups/${groupId} `);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteDefaultGroups = createAsyncThunk(
  'auth/deleteDefaultGroups',
  async ({ domainId, groupId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/auth/profile/domains/${domainId}/default_groups/${groupId} `);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateProfileData = createAsyncThunk(
  'auth/updateProfileData',
  async ({ userNewData = {} }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/profile/update', userNewData);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateProfilePicture = createAsyncThunk(
  'auth/updateProfilePicture',
  async ({ newProfilePicture = {} }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/profile/update/photo', newProfilePicture, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteProfilePicture = createAsyncThunk('auth/updateProfilePicture', async (rejectWithValue) => {
  try {
    const response = await axiosInstance.post('/profile/delete/photo', { photo: null });

    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updateProfilePassword = createAsyncThunk(
  'auth/updateProfilePassword',
  async ({ updatePassParams = {} }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put('/profile/password', updatePassParams);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateDomainInformation = createAsyncThunk(
  'auth/updateDomainInformation',
  async ({ domainId = '', updatedObj = {} }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/user/domains/${domainId}`, updatedObj);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateDomainDefaultQRTemplate = createAsyncThunk(
  'auth/updateDomainInformation',
  async ({ domainId = '', templateID = {} }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`${domainId}/qr/template/${templateID}`);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getUserDomainInformation = createAsyncThunk(
  'auth/getUserDomainInformation',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('/user/domains');

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getMyProfileData = createAsyncThunk('auth/getMyProfileData', async (_, { rejectWithValue }) => {
  try {
    const { data: responseData } = await axiosInstance.get('/profile');

    return responseData;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getMyDomainsList = createAsyncThunk('auth/getMyDomainsList', async (_, { rejectWithValue }) => {
  try {
    const perPage = 50;
    const { data: { meta: { total } } } = await axiosInstance.get(`/profile/domains`);
    const numberOfRequests = Math.ceil(total / perPage);

    const promises = [];
    for (let i = 0; i < numberOfRequests; i++) {
      promises.push(axiosInstance.get(`/profile/domains?per_page=${perPage}&page=${i + 1}`));
    }

    const response = await Promise.all(promises);
    const domains = response.reduce((memo, current) => [...memo, current.data.data], []).flatMap(item => item);

    return { data: domains, meta: response[0].data.meta };
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const logOut = createAsyncThunk('auth/logOut', async (onSuccess = noop, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post('/logout');
    onSuccess();

    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const userModifyAPI = {
  updateUserRoles(uuid, data) {
    return axiosInstance.post(`/users/${uuid}/roles`, data).then(res => res.data);
  },
  lockUser(userID) {
    return axiosInstance.post(`/users/lock/${userID}`).then(res => res.data);
  },
  unLockUser(userID) {
    return axiosInstance.post(`/users/unlock/${userID}`).then(res => res.data);
  },
  userDomains(uuid) {
    return axiosInstance.get(`/users/${uuid}/domains`).then(res => res.data);
  },
  user(uuid) {
    return axiosInstance.get(`/users/${uuid}`).then(res => res.data);
  },
  updateUser(uuid, data) {
    return axiosInstance.put(`/users/${uuid}`, data).then(res => res.data);
  },
  getProfileDomains({ page, per_page }) {
    return axiosInstance.get(`/profile/domains`, { params: { page, per_page } }).then(res => res.data);
  },
};

export const updateUserRefreshToken = async ({ refresh_token = '' }) => {
  const {
    data: { token = '' },
  } = await axiosInstance.post('/update-refresh', { refresh_token });

  return token;
};

export const getTimezoneOptions = async () => axiosInstance.get(`/timezones`).then(res => res.data);

export const setDefaultDomain = createAsyncThunk('profile/domain/setDefaultDomain', async (
  { uuid },
  { rejectWithValue },
) => {
  try {
    const response = await axiosInstance.put('/profile/update/default-domain', { default_domain_uuid: uuid });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
