import axios from 'axios';
import config from 'config';
import { UserLayer } from '../pages/admin/constant';
import getTenant from './getTenant';

let store;

const [topLevelHost] = window.location.hostname.split('.');
const tenant = getTenant(topLevelHost);
const { apiHost: baseURL } = config;

const axiosInstance = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'X-Host': `${document.location.protocol}//${document.location.host}`,
    'X-Tenant': tenant, // Initial tenant
  },
});

export const injectStore = (_store) => {
  store = _store;
};

export const fetchCsrfToken = async () => {
  try {
    const response = await axiosInstance.get('/csrf-token');
    return response.data.token;
  } catch (error) {
    console.error('Failed to fetch CSRF token:', error);
    throw error;
  }
};

axiosInstance.interceptors.request.use((config) => {
  store.getState().tenant.tenant && (config.headers['X-Tenant'] = store.getState().tenant.tenant);
  store.getState().auth.domainID && (config.headers['X-Domain'] = store.getState().auth.domainID);

  const accountRoleType = store.getState().auth.userRoleType;

  if (accountRoleType === UserLayer.Account) {
    store.getState().auth.user?.account?.uuid &&
      (config.headers['X-Account'] = store.getState().auth.user.account.uuid);
  }

  try {
    const userData = localStorage.getItem('userData');
    const auth0Token = localStorage.getItem('auth0Token');
    const target = userData || auth0Token;

    if (target) {
      const { tokenType = 'Bearer', accessToken } = JSON.parse(target);

      if (accessToken) {
        config.headers.Authorization = `${tokenType} ${accessToken}`;
      }
    }
    
    return config;
  } catch (error) {
    console.log(error);
    return config;
  }
});

axiosInstance.interceptors.response.use(
  response => response,
  (error) => {
    if (error.response?.status === 403) {
      window.location.href = '/maintenance/403';
    }
    console.error('API request failed:', error);
    return Promise.reject(error);
  },
);

export default axiosInstance;
