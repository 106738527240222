import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import { activeItem } from 'store/reducers/menu';
import { resetFilters } from 'store/reducers/filters';
import { GridToolbarExport } from '@mui/x-data-grid-pro';

const NavItem = ({ item, level }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const menu = useSelector(state => state.menu);
  const { drawerOpen, openItem } = menu;

  let itemTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps = {
    component: forwardRef((props, ref) => {
      if (item.hasOwnProperty('hideSidebar')) {
        return null;
      }

      return <Link {...props} to={item.url} target={itemTarget} ref={ref} />;
    }),
  };

  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }

  const Icon = item.icon;
  const itemIcon = item.icon ? <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} /> : false;

  const isSelected = openItem.findIndex(id => id === item.id) > -1;

  const { pathname } = useLocation();

  // active menu item on page load
  useEffect(() => {
    if (pathname.includes(item.url)) {
      dispatch(activeItem({ openItem: [item.id] }));
      dispatch(resetFilters()); // temp solution to prevent filters collision
    }
    // eslint-disable-next-line
  }, [pathname, item]);

  const textColor = theme.palette.mode === 'dark' ? 'grey.400' : 'text.secondary';
  const iconSelectedColor = theme.palette.mode === 'dark' && drawerOpen ? 'text.secondary' : 'secondary.main';

  if (item.disabled && !isSelected) {
    return null;
  }

  return (
    <Tooltip
      title={
        drawerOpen ? '' : item.breadcrumbTitle ?? item.title
      }
    >
      <ListItemButton
        {...listItemProps}
        disabled={item.disabled}
        selected={isSelected}
        sx={{
          zIndex: 1201,
          pl: drawerOpen ? `${level * 28}px` : 1.5,
          py: !drawerOpen && level === 1 ? 1.25 : 1,
          ...(drawerOpen && {
            '&:hover': {
              bgcolor: theme.palette.mode === 'dark' ? 'divider' : 'secondary.lighter',
            },
            '&.Mui-selected': {
              bgcolor: theme.palette.mode === 'dark' ? 'divider' : 'secondary.lighter',
              borderRight: `2px solid ${theme.palette.secondary.main}`,
              color: iconSelectedColor,
              '&:hover': {
                color: iconSelectedColor,
                bgcolor: theme.palette.mode === 'dark' ? 'divider' : 'secondary.lighter',
              },
            },
          }),
          ...(!drawerOpen && {
            '&:hover': {
              bgcolor: 'transparent',
            },
            '&.Mui-selected': {
              '&:hover': {
                bgcolor: 'transparent',
              },
              bgcolor: 'transparent',
            },
          }),
        }}
      >
        {itemIcon && (
          <ListItemIcon
            sx={{
              minWidth: 28,
              color: isSelected ? iconSelectedColor : textColor,
              ...(!drawerOpen && {
                borderRadius: 1.5,
                width: 36,
                height: 36,
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover': {
                  bgcolor: theme.palette.mode === 'dark' ? 'secondary.light' : 'secondary.lighter',
                },
              }),
              ...(!drawerOpen &&
              isSelected && {
                bgcolor: theme.palette.mode === 'dark' ? 'secondary.900' : 'secondary.lighter',
                '&:hover': {
                  bgcolor: theme.palette.mode === 'dark' ? 'secondary.darker' : 'secondary.lighter',
                },
              }),
            }}
          >
            {itemIcon}
          </ListItemIcon>
        )}
        {(drawerOpen || (!drawerOpen && level !== 1)) && (
          <ListItemText
            primary={
              <Typography variant="body1" sx={{ color: isSelected ? iconSelectedColor : textColor }}>
                {item.breadcrumbTitle ?? item.title}
              </Typography>
            }
          />
        )}
        {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
          <Chip
            color={item.chip.color}
            variant={item.chip.variant}
            size={item.chip.size}
            label={item.chip.label}
            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
          />
        )}
      </ListItemButton>
    </Tooltip>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
};

export default NavItem;
