export const FETCH_PER_PAGE_COUNT = 100;

export const BE_FE_USER_MAP = {
  created_at: 'createdAt',
  department: 'department',
  domains: 'domains',
  email: 'email',
  first_name: 'firstName',
  full_name: 'fullName',
  id: 'id',
  is_domain_admin: 'isDomainAdmin',
  is_locked: 'isLocked',
  last_active: 'lastActive',
  last_name: 'lastName',
  locked: 'locked',
  permissions: 'permissions',
  roles: 'roles',
  timezone: 'timezone',
  updated_at: 'updatedAt',
  uuid: 'uuid',
  was_locked: 'wasLocked',
  default_domain_redirect_type: 'defaultDomainRedirectType',
  default_domain_uuid: 'defaultDomainUUID',
};

export const FE_BE_USER_MAP = {
  createdAt: 'created_at',
  department: 'department',
  domains: 'domains',
  email: 'email',
  firstName: 'first_name',
  fullName: 'full_name',
  id: 'id',
  isDomainAdmin: 'is_domain_admin',
  isLocked: 'is_locked',
  lastActive: 'last_active',
  lastName: 'last_name',
  locked: 'locked',
  permissions: 'permissions',
  roles: 'roles',
  timezone: 'timezone',
  updatedAt: 'updated_at',
  uuid: 'uuid',
  wasLocked:  'was_locked',
  defaultDomainRedirectType: 'default_domain_redirect_type',
  defaultDomainUUID: 'default_domain_uuid',
};
