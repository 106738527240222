import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axios';
import { FETCH_PER_PAGE_COUNT } from '_api/constants.js';

export const getUserAllRoles = createAsyncThunk('admin/getUserAllRoles', async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get('/roles');

    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getUsersList = createAsyncThunk(
  'admin/getUsersList',
  async ({ userRoleType }, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { role: activeRoleDomain },
      } = getState();

      const { data: { meta: { total } } } = await axiosInstance.get(`/users?per_page=1&page=1`);
      const numberOfRequests = Math.ceil(total / FETCH_PER_PAGE_COUNT);

      const promises = [];
      for (let i = 0; i < numberOfRequests; i++) {
        promises.push(axiosInstance.get(`/users?per_page=${FETCH_PER_PAGE_COUNT}&page=${i + 1}`));
      }

      const response = await Promise.all(promises);
      const users = response.flatMap(item => item.data.data);

      return { data: { data: users, meta: response[0].data.meta }, userType: userRoleType, activeRoleDomain };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getPaginatedUsers = createAsyncThunk(
  'admin/getPaginatedUsers',
  async ({ userRoleType, params }, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { role: activeRoleDomain },
      } = getState();

      const { data: { data: users = [], meta } } = await axiosInstance.get(`/users`, { params });

      return { data: { data: users, meta }, userType: userRoleType, activeRoleDomain };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const createUser = createAsyncThunk('admin/createUser', async ({ userData = {} }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post('/users', userData);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const deleteUsers = createAsyncThunk(
  'admin/deleteUsers',
  async ({ data = {} }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/users/delete`, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
