import { useState, useEffect } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import LogoMain from 'components/logo/LogoMain';
import useTenant from 'hooks/useTenant';
import config from 'config';

import NotificationsList from './Notification';
import Profile from './Profile';
import DomainSelection from './DomainSelection';
import { useTheme } from '@mui/material/styles';

const HeaderContent = () => {
  const { tenant } = useTenant();
  const { defaultTenant } = config;
  const [showLogo, setShowLogo] = useState(false);
  const theme = useTheme();
  const isUpSmall = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    if (tenant !== defaultTenant) {
      setShowLogo(true);
    }
  }, [tenant, defaultTenant]);

  return (
    <Box width="100%" display="flex" justifyContent="space-between" height="61px">
      {isUpSmall && (
        <Box  xs={1}  py={2} px={1} sx={{ width: '235px' }}>
          {showLogo ? <LogoMain tenant /> : null}
        </Box>
      )}
      <Box display="flex" justifyContent="center" alignItems="center">
        <DomainSelection />
      </Box>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <NotificationsList />
        <Profile />
      </Box>
    </Box>
  );
};

export default HeaderContent;
